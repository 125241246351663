import {
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Text,
    VStack
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSupportTickets } from "../../providers/SupportTicketProvider";
import { Link } from 'react-router-dom';

export default function TicketManagement() {

    const { tickets, currentPage, totalTicket, totalResolvedTicket, totalUnresolvedTicket, totalPages, getSupportTicket } = useSupportTickets();

    useEffect(() => {
        console.log(tickets)
    })

    const formatDate = (isoString) => {
        if (!isoString) return "Invalid Date";
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let formattedJoinedDate;

    return (
        <Container maxWidth='9xl' px={6}>

            <Text className='custom' fontSize="lg" fontWeight="500">
                Ticket Management
            </Text>

            <VStack w={{ lg: '80%' }} mt={8} mb={4}>
                <HStack w={'100%'} spacing={8} mb={4}>
                    <Text color='primary.800' px={2} fontWeight={'600'} borderBottom="3px solid #F51E9A">
                        All Tickets ({totalTicket})
                    </Text>

                    <Link to={'/ticket-management/unresolved'}>
                        <Text color={'grey'} fontWeight={'500'}>Unresolved ({totalUnresolvedTicket})</Text>
                    </Link>

                    <Link to={'/ticket-management/resolved'}>
                        <Text color={'grey'} fontWeight={'500'}>Resolved ({totalResolvedTicket})</Text>
                    </Link>
                </HStack>

                {tickets.map((t, index) => {
                    const formattedJoinedDate = formatDate(t.createdAt);
                    return (
                        <Card key={index} py={4} px={4} my={1} w={'100%'}>
                            <VStack alignItems={'start'} spacing={1}>
                                <HStack w={'100%'} justifyContent={'space-between'}>
                                    <Text fontWeight={'600'} color='primary.800'>{t.title}</Text>
                                    <Text fontSize={'sm'} color='primary.800'>
                                        {formattedJoinedDate || 'No Date Available'}
                                    </Text>
                                </HStack>
                                <Text fontSize={'sm'}>
                                    {t.description}
                                </Text>

                                <HStack mt={4} spacing={6}>
                                    <Text fontWeight={'500'}>
                                        Status:
                                        <Text
                                            as="span"
                                            fontSize={'sm'}
                                            color={
                                                t.status === 'Closed' ? 'green.500'
                                                    : t.status === 'Open' ? 'blue.500'
                                                        : t.status === 'Inprocess' ? 'yellow.500'
                                                            : 'gray.500'
                                            }
                                            pl={1}
                                        >
                                            {t.status}
                                        </Text>
                                    </Text>
                                </HStack>
                            </VStack>
                        </Card>
                    );
                })}
            </VStack>
        </Container>
    )
}
